





























import Vue from 'vue'

export default Vue.extend({
  name: 'Header',

  methods: {
    route(location: string) {
      this.$router.push(location);
      window.scrollTo(0, 0)
    }
  },
  data: () => ({
    sections: [
      {
        text: 'Home',
        href: '/',
      },
      {
        text: 'About Us',
        href: '/team',
      },
      {
        text: 'Login',
        href: '/login',
      },
    ],
  }),
})
