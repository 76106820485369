




























































import {Vue} from "vue-property-decorator";
import LottieAnimation from 'lottie-web-vue';

Vue.use(LottieAnimation)

export default Vue.extend({
  name: 'ChooseUs',

  components: {
    LottieAnimation
  },
})
