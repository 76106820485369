<template>
  <v-container class="justify-center align-center pt-16">
    <p class="text-h1">404</p>
    <p class="text-h3 mt-10">Page Not Found</p>
    <v-btn class="mt-16" large color="secondary" href="https://naturalius.com.co">Return to home</v-btn>
  </v-container>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>
