
















































































































































































































































import Vue from "vue";
import VueApexCharts from 'vue-apexcharts'
import kmersJson from '@/assets/mock/data/HeatMap1.json'
import organismJson from '@/assets/mock/data/organismResults.json'
import LottieAnimation from 'lottie-web-vue';

Vue.use(LottieAnimation)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)

export default Vue.extend({
  name: "HomeDashboard",

  components: {
    LottieAnimation
  },
  data: () => ({
    tab: null,
    fab: false,
    drawer: false,
    searched: false,
    searchedSequence: '',
    loading: false,
    sequenceNotFound: false,
    topBarItems: [
      {
        name: 'Search',
        text: 'Search microorganisms using a 16S ribosomal RNA sequence and then create a report.',
      },
      {
        name: 'Reports History',
        text: 'Download your past reports.',
      },
    ],
    sideBarItems: [
      {
        text: 'Home',
        icon: 'mdi-home',
      },
      {
        text: 'Appointments',
        icon: 'mdi-seat',
      },
      {
        text: 'Configuration',
        icon: 'mdi-tune',
      },
      {
        text: 'Account Balance',
        icon: 'mdi-account',
      },
      {
        text: 'Support',
        icon: 'mdi-help',
      },
    ],
    json: {},
    completeOrganismData: [{
      "organismName": "MO_NAME",
      "organismImage": "cocoa.jpeg",
      "compoundGroup": "CMP_GROUP",
      "compoundName": "CMP_NAME",
      "compoundImage": "cocoa.jpeg",
      "characteristic": "REPLACE",
      "characteristicImage": "",
      "compoundType": "GOOD",
      "sequence": ""
    }],
    organismData: [{
      "organismName": "MO_NAME",
      "organismImage": "cocoa.jpeg",
      "compoundGroup": "CMP_GROUP",
      "compoundName": "CMP_NAME",
      "compoundImage": "cocoa.jpeg",
      "characteristic": "REPLACE",
      "characteristicImage": "",
      "compoundType": "GOOD",
      "sequence": ""
    }],
    completeDataSeries: [{name: 'n', data: [{x: '', y: 0}]}],
    series: [{name: 'n', data: [{x: '', y: 0}]}],
    chartOptions: {
      legend: {
        position: 'bottom',
        horizontalAlign: 'right',
        onItemHover: {
          toggleDataSeries: true
        },
      },
      chart: {
        height: 1000,
        type: 'heatmap',
      },
      dataLabels: {
        enabled: false
      },
      grid: {
        padding: {
          right: 50,
          left: 30
        },
      },
      xaxis: {
        title: 's',
        type: 'category',
        categories: ['10', '20', '30', '40', '50', '60']
      },
      plotOptions: {
        heatmap: {
          colorScale: {
            ranges: [
              {
                from: -100,
                to: 9,
                color: '#482979',
                name: '10',
              },
              {
                from: 10,
                to: 19,
                color: '#39558C',
                name: '20',
              },
              {
                from: 20,
                to: 29,
                color: '#297A8E',
                name: '30',
              },
              {
                from: 30,
                to: 39,
                color: '#1E9D89',
                name: '40',
              },
              {
                from: 40,
                to: 49,
                color: '#42BE70',
                name: '50',
              },
              {
                from: 50,
                to: 59,
                color: '#60CA60',
                name: '60',
              },
              {
                from: 60,
                to: 100,
                color: '#FBE524',
                name: '70',
              },
            ]
          },
          distriduted: true,
          radius: 0
        }
      },
    }
  }),
  methods: {
    onScroll(e: { target: { scrollTop: never; }; }) {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.fab = top > 20
    },
    toTop() {
      this.$vuetify.goTo(0)
    },
    generateDataSeries() {
      let json = kmersJson
      for (let j = 0; j < json.length; j++) {
        let obj = JSON.parse(JSON.stringify(json[j]))
        let keys: string[] = Object.keys(obj)

        let kmers = []
        for (let k = 1; k < keys.length; k++) {
          let label = keys[k]
          let kmer = {
            x: label,
            y: obj[label]
          }
          kmers.push(kmer)
        }

        this.completeDataSeries.push({
          name: obj.Organism,
          data: kmers
        })
      }
    },
    generateDataDescriptions() {
      let json = organismJson
      for (let j = 0; j < json.length; j++) {
        let obj = JSON.parse(JSON.stringify(json[j]))
        this.completeOrganismData.push(obj)
      }
    },
    async showSingleSearch() {
      this.loading = true
      let sleep = (ms: number | undefined) => new Promise(resolve => setTimeout(resolve, ms))
      await sleep(Math.floor(Math.random() * (3000 - 1000 + 1) + 1000))
      this.loading = false

      // Description
      let organismDescription = this.completeOrganismData.filter(e => e.sequence == this.searchedSequence)[0]
      if (organismDescription == undefined) {
        this.sequenceNotFound = true
        await sleep(4000)
        this.sequenceNotFound = false
        return
      }
      this.completeOrganismData = this.completeOrganismData.filter(item => item !== organismDescription)
      this.organismData.push(organismDescription)

      // Heatmap
      let heatmap: any = this.completeDataSeries.filter(e => e.name == organismDescription.organismName)[0]
      this.completeDataSeries = this.completeDataSeries.filter(item => item !== heatmap)
      this.series.push(heatmap)

      this.searched = true
      this.sequenceNotFound = false
    },
    saveSession() {
      this.$router.go(0)
    }
  },
  beforeMount() {
    this.generateDataSeries()
    this.generateDataDescriptions()
    this.series.shift()
    this.completeDataSeries.shift()
    this.organismData.shift()
    this.completeOrganismData.shift()
  }
})
