

















































import Vue from 'vue'
import tech from '@/assets/images/tech-chemistry.jpeg'
import TS from '@/assets/videos/TS.webm'
import TSmp4 from '@/assets/videos/TS.mp4'
import EZ from '@/assets/videos/enzima.webm'
import EZmp4 from '@/assets/videos/enzima.mp4'

export default Vue.extend({
  name: "MainCarrousel",

  data() {
    return {
      items: {
        1: {
          'title': 'Taking biology to the next level',
          'text': 'We make use of biotechnology to produce innovative compounds, predictive models, and simulations for chemical processes of the utmost importance',
          'image': '',
          'video': [TS, TSmp4],
          'background': 'background-color: black; height: 100%; width: 100%; position:absolute; top:0; z-index: -2',
        },
        2: {
          'title': 'Harness the endless power of nature',
          'text': 'Our tremendous biodiversity represents a unique chance of taking advantage of an extraordinarily immense reservoir of molecules',
          'image': tech,
          'video': '',
          'background': 'background-color: #184154; height: 100%; width: 100%; position:absolute; top:0; z-index: -2',
        },
        3: {
          'title': 'Computation sparks chemical discovery',
          'text': 'The integration of advanced methods in the realm of chemistry and computing provide unprecedent technological advances and opportunities',
          'image': '',
          'video': [EZ, EZmp4],
          'background': 'background-color: white; height: 100%; width: 100%; position:absolute; top:0; z-index: -2',
        },
      }
    }
  },
})
