































import Vue from 'vue'

export default Vue.extend({
  name: 'Footer',

  data: () => ({
    references: [
      {
        icon: 'mdi-linkedin',
        href: 'https://www.linkedin.com/company/naturalius',
      },
      {
        icon: 'mdi-facebook',
        href: 'https://www.facebook.com/Naturalius-103996428632104/',
      },
      {
        icon: 'mdi-github',
        href: 'https://github.com/naturalius',
      },
      {
        icon: 'mdi-youtube',
        href: 'https://www.youtube.com/channel/UC1U1DCSpBS9HOIY2dHS32Xw',
      },
      {
        icon: 'mdi-twitter',
        href: 'https://twitter.com/NaturaliusB',
      },
      {
        icon: 'mdi-instagram',
        href: 'https://www.instagram.com/naturaliusb/',
      },
    ],
  }),
})
