



























































import Vue from "vue";

export default Vue.extend({
  name: 'Login',

  data: () => ({
    login: '',
    password: '',
    validLogin: true,
  }),
  methods: {
    goToDashboard() {
      if (this.login == 'luker' && this.password == 'casa') {
        this.validLogin = true
        setTimeout(() => {
          //
        }, 200)
        this.$router.push('/dashboard');
        window.scrollTo(0, 0)
      } else {
        this.validLogin = false
      }
    }
  }
})

